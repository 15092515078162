import './backdrop.css'
import { Context } from "../../helpers/context"
import { useState, useContext, useRef } from 'react'
import Autocomplete from "react-google-autocomplete"

const Backdrop = () => {
  const [user, setUser] = useContext(Context)

  const backdropHandler = () => {
    setUser({ ...user, backdrop: false, backdrop_type: '' })
  }

  const addressRef = useRef(null)
  const [cor, setCor] = useState([])
  const [currentPlace, setCurrentPlace] = useState()


  const addressChanger = (e) => {
    const ad = e.target.value
    setCurrentPlace(ad)
  }


  const [city, setCity] = useState()
  const [zip, setZip] = useState()
  const [state, setState] = useState()
  const [country, setCountry] = useState()

  const placeController = (place) => {
    let address
    let zip = "";
    let city;
    let state;
    let number;
    let country;
    console.log(place)
    place.address_components.map(item => {
      // console.log("item")
      // console.log(item)
      if (item.types[0] === "route") address = item.long_name
      if (item.types[0] === "street_number") number = item.long_name
      if (item.types[0] === "postal_code") zip = item.short_name
      if (item.types[0] === "administrative_area_level_3" || item.types[0] === "locality") city = item.long_name
      if (item.types[0] === "administrative_area_level_1") state = item.short_name
      if (item.types[0] === "country") country = item.long_name
    })

    if (number) {
      address = number + " " + address
    }
    if (!address) {
      address = city
    }
    if (!address) {
      address = state
    }

    setCurrentPlace(address)
    setZip(zip)
    setCity(city)
    setState(state)
    setCountry(country)
    setCor([place.geometry.location.lat(), place.geometry.location.lng()])

    // console.log(address)
    // console.log(zip)
    // console.log(city)
    // console.log(state)
    // console.log(country)
  }


  const addressSaveHandler = () => {


    localStorage.setItem('address', JSON.stringify({ latitude: cor[0], longitude: cor[1], address: currentPlace, zip: zip, city: city, state: state, country: country }))

    window.location.reload();


    // setUser({ ...user, cordinates: cor, address: currentPlace, backdrop: false, location: '' })

  }

  function geolocation() {
    if (navigator.geolocation) {
      navigator.geolocation.getCurrentPosition(showPosition)
    } else {
      // I believe it may also mean geolocation isn't supported
      console.log('Geolocation denied')
    }
  }

  function showPosition(position) {
    console.log(position.coords.latitude)
    console.log(position.coords.longitude)
  }
  // geolocation()

  return (
    <>
      <div className="backdrop_main" >
        <div className="backdrop_wrapper">
          <div className="backdrop_left global_scroll">
            {/* Below is the user address filed */}

            <div className="close_backdrop">
              <i className="fa fa-close" onClick={backdropHandler}></i>
            </div>
            <div className="backdrop_address_input">
              <div className="address_input_wrapper">
                <label htmlFor="">New Address</label>
                <Autocomplete
                  className=""
                  options={{
                    // types: ['geocode',],
                    // (regions)
                    // 'address'
                    types: ["(regions)"],
                    componentRestrictions: { country: "us" },
                  }}
                  apiKey={process.env.REACT_APP_MAP_KEY}
                  onPlaceSelected={placeController}
                  onChange={addressChanger}
                  value={currentPlace}
                  placeholder="Address"
                  ref={addressRef}
                />

                <button onClick={addressSaveHandler} className='address_save'>Save</button>
                <p className='small'>We do not share your location with anyone.</p>
              </div>
            </div>

          </div>
          <div className="backdrop_right" onClick={backdropHandler}>

          </div>
        </div>
      </div>
    </>
  )
}
export default Backdrop